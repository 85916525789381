<template>
  <div>
    <div class="row">
      <label class="col-sm-4 col-md-4 col-form-label">Address</label>
      <div class="col-sm-8 col-md-8">
        <address-lookup :readonly="readonly" :address="address" :show-address-only="true" @update:field="$emit('update', $event)" :hide-label="true" />
      </div>
    </div>
    <template v-if="readonly && address">
      <div class="row">
        <label class="col-sm-4 col-form-label">City</label>
        <div class="col-sm-8"><p class="font-bold">{{ address.city }}</p></div>
      </div>
      <div class="row">
        <label class="col-sm-4 col-form-label">State</label>
        <div class="col-sm-8"><p class="font-bold">{{ address.state }}</p></div>
      </div>
      <div class="row">
        <label class="col-sm-4 col-form-label">Zip Code</label>
        <div class="col-sm-8"><p class="font-bold">{{ address.zip_code }}</p></div>
      </div>
    </template>
  </div>
</template>
<script>

import AddressFields from '@/shared/address_fields.vue'

export default {
  name: 'AdminAddressFields',
  extends: AddressFields,
}
</script>
